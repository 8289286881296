import React from 'react';
export default function ContactForm() {
  return (
    <section>
      <h2>Get in touch</h2>
      <form name="contact" method="POST" data-netlify="true">
        <input
          type="hidden"
          name="form-name"
          value="contact"
          aria-label="Email"
        />
        <div className="fields">
          <div className="field half">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              aria-label="Name"
            />
          </div>
          <div className="field half">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              aria-label="Email"
            />
          </div>
          <div className="field">
            <textarea
              name="message"
              id="message"
              placeholder="Message"
              aria-label="Message"
            />
          </div>
        </div>
        <ul className="actions">
          <li>
            <button type="submit" className="primary">
              Send
            </button>
          </li>
        </ul>
      </form>
    </section>
  );
}
